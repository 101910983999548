h1,
h2,
h3{
  font-weight: 700;
  line-height: 1.5;
}

h1{
  font-size: 21px;
  font-weight: 600;
  line-height: 1.5;
  color: $primary;
}

h2{
  font-size: 1.5rem;
}

.height-line{
  font-size: 1.4rem;
  line-height: 1;
}

h3{
  font-size: 1rem;
}

.contact-title{
  font-size: 1.5rem;
  font-weight: 400;
}

.bg-hero{
  font-weight: 600;
  background: url("../images/hero-bg.png") no-repeat top center;
}

.header-secondary{
  background-position: bottom center;
}

.bg-hero a{
  color: #fff;
}

.bg-hero .banner{
  padding-top: 8.525rem;
  padding-bottom: 8.525rem;
  font-size: 2.625rem;
  line-height: .86;
}

.bg-hero .banner-secondary{
  padding-top: 20px;
  padding-bottom: 70px;
}

.bg-hero .banner small{
  font-size: 1.3125rem;
  line-height: 2.19;
}

.hrline{
  width: 124px;
  height: 7px;
  margin: 0 auto;
  margin-top: 22px;
  background-color: #37ca25;
}

.p-primary{
  padding-right: 40px;
  padding-left: 40px;
  font-weight: 700;
}

.headline{
  font-size: 1.75rem;
  line-height: 1.57;

}

.card{
  background: none;
  border: none;
}

.card-body {
  padding: 0;
}

.card-footer {
  padding: 0;
  background: none;
  border: none;
}

.max-container{
  max-width: 1440px;
}

.contact{
  padding: 100px 0;
  background: linear-gradient(90deg, #f5f6f6 50%, #d8d8d8 50%);
}

.content{
  font-size: 18px;
  line-height: 34px;
}

.about{
  background: url("../images/atomoweb-light-blue.png") no-repeat -30px 0;
}

.about-left{
  padding-left: 203px;
}

.reviews{
  padding: 100px 0;
  background: #6a8ae2;
}

.reviews .content{
  max-width: 80%;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.reviews .content small{
  font-size: 14px;
  font-weight: 400;
}

.review-text{
  margin-bottom: 50px;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  color: #fff;
}

.webdev .float-left{
  height: 125px;
}

.webdev p{
  margin-bottom: 0;
  font-size: 21px;
  line-height: 30px;
}

.webdev h1{
  font-size: 42px;
  line-height: 36px;
}

@include media-breakpoint-down(md){
  .bg-hero .banner{
    padding-top: 3.525rem;
    padding-bottom: 3.525rem;
    font-size: 2rem;
    line-height: .86;
  }
}

@include media-breakpoint-down(sm){
  .bg-hero .banner{
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .bg-hero .banner-secondary{
    padding-top: 0;
    padding-bottom: 40px;
    font-size: 1.5rem;
  }

  .reviews{
    padding: 20px 0;
    background: #6a8ae2;
  }

  .contact{
    padding: 20px 0;
    background: #f5f6f6;
  }

  .about-left{
    padding-left: 0;
  }

  .webdev .float-left{
    float: left;
    height: 105px;
  }

  .webdev img{
    width: 75px;
    height: 75px;
  }

  .webdev p{
    font-size: 20px;
    line-height: 20px;
  }

  .webdev h1{
    font-size: 24px;
    line-height: 36px;
  }
}
